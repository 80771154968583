import { createComponent } from "../private/editor/base";
import Bold from "@tiptap/extension-bold";
import Italic from "@tiptap/extension-italic";
import Underline from "@tiptap/extension-underline";

export default createComponent({
  name: "SimpleEditor",
  marks: [
    {
      ext: Bold,
      title: "Bold",
      shortcut: "Ctrl+B",
      active: (editor) => editor.isActive("bold"),
      handle: (editor) => editor.chain().focus().toggleBold().run(),
      disabled: (editor) => !editor.can().chain().focus().toggleBold().run(),
      icon: "format_bold",
    },
    {
      ext: Italic,
      title: "Italic",
      shortcut: "Ctrl+I",
      active: (editor) => editor.isActive("italic"),
      handle: (editor) => editor.chain().focus().toggleItalic().run(),
      disabled: (editor) => !editor.can().chain().focus().toggleItalic().run(),
      icon: "format_italic",
    },
    {
      ext: Underline,
      title: "Underline",
      shortcut: "Ctrl+U",
      active: (editor) => editor.isActive("underline"),
      handle: (editor) => editor.chain().focus().toggleUnderline().run(),
      disabled: (editor) =>
        !editor.can().chain().focus().toggleUnderline().run(),
      icon: "format_underlined",
    },
  ],
});
